exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-developer-tsx": () => import("./../../../src/pages/developer.tsx" /* webpackChunkName: "component---src-pages-developer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-announcement-tsx": () => import("./../../../src/templates/Announcement.tsx" /* webpackChunkName: "component---src-templates-announcement-tsx" */),
  "component---src-templates-concert-piece-tsx": () => import("./../../../src/templates/ConcertPiece.tsx" /* webpackChunkName: "component---src-templates-concert-piece-tsx" */)
}

